<template>
    <div class="margin-bottom-50">
        <div class="content-viewtitle">服務權紀錄</div>
        <Table
            v-if="tableShowData.length > 0 || isLoading"
            :isLoading="isLoading"
            :columnConfig="columnConfig"
            :rows="tableShowData"
        >
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { insuranceSalesColumns } from '@/assets/javascripts/tia/setting'

export default {
    name: 'InsuranceSales',
    components: {
        Table
    },
    props: {
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        getGa: function () {},
        transformData: function (rows) {
            return rows.map((row) => {
                //比率
                row.rate = {
                    value:
                        row?.rateUnit === '%'
                            ? this.$addPointNumber(row?.rate, 2) ?? ''
                            : row?.rate ?? '',
                    unit: row?.rateUnit || ''
                }
                row.isVoid =
                    row.isVoid === true
                        ? '是'
                        : row.isVoid === false
                        ? '否'
                        : '-'
                return row
            })
        }
    },
    computed: {
        tableShowData: function () {
            if (!this.dataList.insSales || this.dataList.insSales.length === 0)
                return []
            let dataset = _.cloneDeep(this.dataList.insSales)
            dataset = this.transformData(dataset)
            return dataset
        }
    },
    data() {
        return {
            columnConfig: insuranceSalesColumns
        }
    }
}
</script>

<style lang="scss" scoped>
.content-viewtitle {
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    transition: margin 1s ease-in-out;
    color: $sixth-black;
    font-size: 24px;

    @media screen and (max-width: 576px) {
        padding: 0 0 10px 0;
        font-size: 20px;
    }
}

:deep(.table-container) {
    margin-bottom: 0;
    padding-bottom: 10px;
}

.table-wrapper {
    :deep(.table) {
        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
        }
    }
}
</style>
